"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    busyDelay: 500,
    api_base: "https://testapi.totaltyremanager.eu",
    api_routes: {
        LOGIN: "/login",
        QUERY: "/query",
        PRICE_QUERY_IPC: "/priceQueryIPC",
        FLEET_QUERY_IPC: "/fleetQueryIPC",
        POD_QUERY_IPC: "/podQueryIPC",
        COMMENT: "/comment",
        ATTACHMENT: "/attachment",
        REPORT: "/reports",
        CUSTOMERS: "/customer",
        DELIVERY_REFS: "/query/deliveryRef"
    },
    storage_keys: {
        USER: "USER",
        PREFS: "PREFS"
    },
    user_roles: {
        USER: "ROLE_USER",
        ADMIN: "ROLE_ADMIN",
        SUPER_ADMIN: "ROLE_SUPER_ADMIN"
    }
};
